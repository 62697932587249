import * as THREE from 'three'

import * as dat from 'lil-gui'
import gsap from 'gsap'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import model from './static/models/painters_tools.glb'
import modelHead from './static/models/A_Head.glb'

import textureURL1 from './static/textures/particles/1.png';
import textureURL2 from './static/textures/particles/2.png';
import textureURL3 from './static/textures/particles/10.png';

THREE.ColorManagement.enabled = false

/**
 * Debug
 */
// const gui = new dat.GUI()

const parameters = {
    materialColor: '#ffeded'
}


// gui
//     .addColor(parameters, 'materialColor')
//     .onChange(() => {
//         material.color.set(parameters.materialColor)
//         particlesMaterial.color.set(parameters.materialColor)
//     });


/**
 * Base
 */
// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}


/**
 * Objects
 */
// texture
const textureLoader = new THREE.TextureLoader()

const gradientTexture = textureLoader.load('static/textures/gradients/3.jpg')
gradientTexture.magFilter = THREE.NearestFilter
const matcapTexture = textureLoader.load('static/textures/matcaps/3.png')

const particleTextures = [

    textureLoader.load(textureURL1),
    textureLoader.load(textureURL2),
    textureLoader.load(textureURL3)
]

const initialParticleTexture = particleTextures[0]


// Material
const material =  new THREE.MeshToonMaterial({ 
    color: parameters.materialColor,
    gradientMap: gradientTexture
})


// Model
const gltfLoader = new GLTFLoader()

let paletteModel
let head 

gltfLoader.load(model,
(gltf) =>
{
    // console.log('success')
    // console.log(gltf)

    paletteModel = gltf.scene
    paletteModel.position.y = - objectsDistance * 0
    paletteModel.position.x = .5
    paletteModel.scale.set(.5, .5, .5)

    // Traverse the paletteModel to apply the material to all meshes
    paletteModel.traverse((child) => {
        if (child.isMesh) {
            child.material = material;
        }
    })

    scene.add(paletteModel)
    sectionMeshes.push(paletteModel)

},
(progress) =>
{
    // console.log('progress')
    // console.log(progress)
},
(error) =>
{
    // console.log('error')
    // console.log(error)
}
)


// Head 
gltfLoader.load(modelHead,
    (gltf) =>
    {
    head = gltf.scene
    head.position.y = - objectsDistance * 2
    head.position.x = .5
    head.scale.set(3, 3, 3)

    // Traverse the paletteModel to apply the material to all meshes
    head.traverse((child) => {
        if (child.isMesh) {
            child.material = material;
        }
    })

    scene.add(head)
    sectionMeshes.push(head)
    
    }
)



// Meshes
const objectsDistance = 1.5

const mesh1 = new THREE.Mesh(
    new THREE.TorusGeometry(0.4, 0.2, 16, 60),
    material
)
const mesh2 = new THREE.Mesh(
    new THREE.BoxGeometry(.5, .5, .5),
    material
)
const mesh3 = new THREE.Mesh(
    new THREE.TorusKnotGeometry(0.4, 0.17, 100, 16),
    material
)

// model.position.y = - objectsDistance * 0
mesh1.position.y = - objectsDistance * 0
mesh2.position.y = - objectsDistance * 1
mesh3.position.y = - objectsDistance * 2

// model.position.x = .5
mesh1.position.x = .5
mesh2.position.x = -.5
mesh3.position.x = .5

scene.add(mesh2)

const sectionMeshes = [ mesh1, mesh2, mesh3 ]

// Define a function to calculate the multiplier
function calculateMultiplier(width, height) {
    // Replace this with your own logic
    const multiplier = Math.min(width, height) * 0.0008;
    return multiplier;
}

// Fonts
const fontLoader = new FontLoader()
fontLoader.load(
    'static/fonts/helvetiker_regular.typeface.json',
    (font) => {
        let textGeometry, text;

        function makeTextGeometry(size = 0.2) { 
            textGeometry = new TextGeometry(
                'MATVEICHENKOV',
                {
                    font: font,
                    size,
                    height: .2,
                    curveSegments: 5,
                    bevelEnabled: true,
                    bevelThickness: 0.03,
                    bevelSize: 0.02,
                    bevelOffset: 0,
                    bevelSegment: 3
                }
            )
            textGeometry.center()
            const material = new THREE.MeshMatcapMaterial({ matcap: matcapTexture })
            if (text) scene.remove(text);
            text = new THREE.Mesh(textGeometry, material)

            text.position.y = .8;

            scene.add(text);
        }

        const textDivisor = 5500;

        window.addEventListener("resize", (e) => {
            let newSize = window.innerWidth / textDivisor;     
            makeTextGeometry(newSize);
        });

        makeTextGeometry(window.innerWidth / textDivisor);

        // gui.add({ size: 0.5 }, "size", 0, 3).onChange(function (value) {
        //     textGeometry.dispose();
        //     makeTextGeometry(value);
        // });

      
    });

/**
 * Particles
 */
//Geometry
const particlesCount = 3000
const positions = new Float32Array(particlesCount * 3)

for(let i = 0; i < particlesCount; i++)
{
    positions[i * 3 + 0] = (Math.random() - 0.5) * 10
    positions[i * 3 + 1] = objectsDistance * 0.5 - Math.random() * objectsDistance * sectionMeshes.length
    positions[i * 3 + 2] = (Math.random() - 0.5) * 10
}

const particlesGeometry = new THREE.BufferGeometry()
particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))

// Material
const particlesMaterial = new THREE.PointsMaterial({
    color: parameters.materialColor,
    size: 0.5,
    map: initialParticleTexture,
    sizeAttenuation: true,
    transparent: true,
    alphaTest: .01,
    depthWrite: false,
    blending: THREE.AdditiveBlending,
    alphaMap: initialParticleTexture
    
})

// Points
const particles = new THREE.Points(particlesGeometry, particlesMaterial)
scene.add(particles)

/**
 * Light
 */
const directionalLight = new THREE.DirectionalLight('#ffffff', 1)
directionalLight.position.set(1, 1, 0)
scene.add(directionalLight)

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

  // Update the multiplier
    multiplier = calculateMultiplier(sizes.width, sizes.height)
       
})

/**
 * Camera
 */
const cameraGroup = new THREE.Group()
scene.add(cameraGroup)

// Base camera
const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100)
camera.position.z = 4
cameraGroup.add(camera)

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true
})
renderer.outputColorSpace = THREE.LinearSRGBColorSpace

renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Scroll
 */
let scrollY = window.scrollY
let currentSection = 0

window.addEventListener('scroll', () =>
{
    scrollY = window.scrollY;
    const newSection = Math.round(scrollY / sizes.height)

    if (newSection != currentSection)
    {
        currentSection = newSection;
        console.log('Section changed to:', currentSection)

       // Update particle texture based on the new section
       if (currentSection < particleTextures.length) {
        particlesMaterial.map = particleTextures[currentSection];
        particlesMaterial.alphaMap = particleTextures[currentSection];
        particlesMaterial.needsUpdate = true; // Ensure the material update is recognized by Three.js
    }

        // Animate based on section. If paletteModel should always animate, it's handled directly
        if (paletteModel && sectionMeshes.includes(paletteModel))
        {
            gsap.to(paletteModel.rotation,
                {
                duration: 1.5,
                ease: 'power2.inOut',
                x: '+=6',
                y: '+=3',
                z: '+=1.5'
            });
        }

        // Head handled directly
        if (head && sectionMeshes.includes(head))
        {
            gsap.to(head.rotation,
                {
                duration: 1.5,
                ease: 'power2.inOut',
                // x: '+=3',
                y: '+=6',
                // z: '+=1.5'
            });
        }


        // Continue with animations for other sections if applicable
        sectionMeshes.forEach((mesh, index) =>
        {
            if (index === currentSection) {
                gsap.to(mesh.rotation, {
                    duration: 1.5,
                    ease: 'power2.inOut',
                    x: '+=6',
                    y: '+=3',
                    z: '+=1.5'
                })
            }
        })
    }
})


/**
 * Cursor
 */
const cursor = {}
cursor.x = 0
cursor.y = 0

window.addEventListener('mousemove', (event) => 
{
    cursor.x = event.clientX / sizes.width - 0.5
    cursor.y = event.clientY / sizes.height - 0.5
    
})

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0

// Global partcile animation control
let particleMovement

const startParticleAnimation = () => {
    particleMovement = gsap.to(particlesGeometry.attributes.position.array, {
        duration: 60, // Duration in seconds, adjust for slower or faster movement
        z: "+=5", // Moves particles 5 units closer to the camera over the duration
        ease: "none",
        repeat: -1, // Infinite loop
        modifiers: {
            z: gsap.utils.unitize(z => parseFloat(z) % 50) // Adjust modulo for bounds of movement
        },
        onUpdate: () => {
            // Update geometry positions after modification
            particlesGeometry.attributes.position.needsUpdate = true
        }
    })
}

startParticleAnimation()  // Call this function when initializing your scene or right after defining it

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime
  
      // Update particle positions to move toward the viewer
      const positions = particlesGeometry.attributes.position.array;
      const forwardZ = 10; // Define a Z position where particles should reset (adjust based on scene)
      const resetZ = 1; // Z position to reset particles to (adjust based on scene)
  
      for (let i = 2; i < positions.length; i += 3) {
          positions[i] += deltaTime * 0.3;  // Adjust speed here, moving towards the camera
          if (positions[i] > forwardZ) {
              positions[i] = resetZ; // Reset particle position to the far end
          }
      }
      particlesGeometry.attributes.position.needsUpdate = true;

    // Animate Camera
    camera.position.y = - scrollY /  sizes.height * objectsDistance

    const parallaxX = cursor.x * 0.5
    const parallaxY = cursor.y * 0.5
    
    cameraGroup.position.y += (parallaxY - cameraGroup.position.y) * 5 * deltaTime
    cameraGroup.position.x += (parallaxX - cameraGroup.position.x) * 5 * deltaTime

    // Animate Meshes
    for (const mesh of sectionMeshes)
        if (mesh === head)
    {
            mesh.rotation.y += deltaTime * 0.12
              
    }
        else
    {
        mesh.rotation.x += deltaTime * 0.1 
        mesh.rotation.y += deltaTime * 0.12
    }

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()